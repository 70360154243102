<template lang="pug">
  section.slice-call-out-row.min-h-130.md_min-h-16vw.px-20.bg-teal.py-20.md_py-60.flex.items-center
    rich-text.w-full.md_w-8x12.md_ml-3x12.md_px-20.text-18.md_text-20.xl_text-24(v-html="slice.text")
</template>

<script>
export default {
  name: 'SliceCallOutRow',
  props: ['slice']
}
</script>

<style>
</style>
