<template lang="pug">
  article.programme-index
    template(v-if="doc")
      slice-body(:slices="doc.featuredProgramme", :templates="templates")
</template>

<script>
import SliceProgrammeThumbLarge from '@/slices/SliceProgrammeThumbLarge'
import SliceProgrammeThumbsSmall from '@/slices/SliceProgrammeThumbsSmall'
import SliceCallToActionRow from '@/slices/SliceCallToActionRow'
import SliceNewsletterSignUpRow from '@/slices/SliceNewsletterSignUpRow'
import SliceCallOutRow from '@/slices/SliceCallOutRow'
export default {
  name: 'ProgrammeIndex',
  data () {
    return {
      doc: undefined,
      templates: {
        featuredProgramme_largeEntry_BlockType: SliceProgrammeThumbLarge,
        featuredProgramme_smallEntries_BlockType: SliceProgrammeThumbsSmall,
        featuredProgramme_callToAction_BlockType: SliceCallToActionRow,
        featuredProgramme_newsletterSignUp_BlockType: SliceNewsletterSignUpRow,
        featuredProgramme_callOutRow_BlockType: SliceCallOutRow
      }
    }
  },
  methods: {
    async get () {
      this.doc = await this.$store.dispatch('getSingle', 'home')
    }
  },
  created () {
    this.get()
  },
  metaInfo () {
    return this.$store.state.globals.metaDescription && {
      title: null, // use site title default
      meta: this.$store.getters.meta({ title: 'Programme' })
    }
  }
}
</script>
