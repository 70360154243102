<template lang="pug">
  container-panel-active.slice-programme-thumb-large
    programme-entry-thumb(v-if="slice.entry", :entry="slice.entry[0]", :reverse="slice.format === 'imageText'")
</template>

<script>
import ProgrammeEntryThumb from '@/components/programme/ProgrammeEntryThumb'
export default {
  name: 'SliceProgrammeThumbLarge',
  props: {
    slice: Object,
    index: { type: Number, default: 0 }
  },
  components: { ProgrammeEntryThumb }
}
</script>

<style>
</style>
