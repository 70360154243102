<template lang="pug">
  flex-link.slice-call-to-action-row.call-to-action-row.bg-pink.h-130.md_min-h-16vw.relative.px-20.text-center.flex.flex-wrap.items-center.justify-end.px-20.text-18.xl_text-24(:link="slice.flexLink")
    //- center/right-ward on panel-open routes (Home)
    .flex.w-full.justify-center.md_px-20(:class="{'md_w-10x12': $route.meta.panelOpen}")
      | {{ slice.text }}
      flex-link.mx-auto.md_mr-0.md_ml-50.pointer-events-auto(@click.native.stop, :link="slice.boxLink", v-if="slice.boxLink.url")
        btn-boxed {{ slice.boxLink.text }}
</template>

<script>
export default {
  name: 'SliceCallToActionRow',
  props: {
    slice: Object
  }
}
</script>

<style>
</style>
