<template lang="pug">
  .programme
    panel-left(ref="panel", :keepOpen="!panelCollapsed")
      nav.children-mt-lh(v-if="isProgramme", role="navigation")
        ul
          li(v-for="item in menu")
            flex-link.btn-theme-text.btn-theme-text--lazy(:link="item.flexLink") {{ item.flexLink.text }}

        ul
          li(v-for="feed in dateFeeds")
            router-link.btn-theme-text(:to="feed[1]") {{ feed[0] }}

        ul
          li(v-for="tag in tags")
            router-link.btn-theme-text.capitalize(:to="`/programme/tagged/${tag.slug}`") {{ tag.title }}

    //- (filter btn mbl)
    button.h-60.px-20.flex.w-full.items-center.md_hidden.focus_outline-none(v-if="$route.meta.filterBtn", @click="$refs.panel && $refs.panel.open()") {{ $route.path === '/' ? 'Expand Menu': 'Filter' }}

    transition(name="pagefade", @after-leave="$root.$emit('afterRouteLeave')")
      router-view(:key="$route.path")

</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Programme',
  data () {
    return {
      scrollBottom: false,
      dateFeeds: [
        ['Now', '/programme/now'],
        ['Next', '/programme/next'],
        ['Past', '/programme/past']
      ]
    }
  },
  computed: {
    ...mapState({
      menu: state => state.globals?.programmeMenu || [],
      tags: state => state.globals?.tags || []
    }),
    ...mapGetters(['is']),
    isProgramme () {
      return this.$route.name?.includes('programme')
    },
    panelCollapsed () {
      return this.$route.name.includes('entry') || this.$route.name === 'programme-page'
    }
  },
  methods: {
    openPanelLnd () {
      if (this.is('md')) {
        return this.$refs.panel?.open()
      }
    },
    closePanel () {
      if (this.panelCollapsed) {
        return this.$refs.panel?.close()
      }
    }
  },
  mounted () {
    this.$root.$on('relatedPostsVisible', this.openPanelLnd)
    this.$root.$on('relatedPostsHidden', this.closePanel)
  }
}
</script>

<style>
</style>
