<template lang="pug">
  .slice-programme-thumb-small.md_px-20.flex.justify-end
    .w-full.md_w-10x12.md_px-20
      .w-full.md_w-full_80.md_-ml-40.flex.flex-wrap
        programme-entry-thumb.w-full.sm_w-1x2(v-for="(entry,i) in slice.entries", :entry="entry", :reverse="slice.format === 'imageText'", size="small", :key='i')
</template>

<script>
import ProgrammeEntryThumb from '@/components/programme/ProgrammeEntryThumb'
export default {
  name: 'SliceProgrammeThumbsSmall',
  props: {
    slice: Object,
    index: { type: Number, default: 0 }
  },
  components: { ProgrammeEntryThumb }
}
</script>

<style>
</style>
