<template lang="pug">
  .video-carousel.relative
    template(v-if="slides && slides.length")
      //- slides...
      figure(v-for="(slide, i) in slides", v-if="current === i")
        video-plyr-js(:video="slide.video && slide.video[0]", :embed="slide.vimeoYoutubeEmbed", :autoplayMuted="true", v-bind="$attrs", :loop="slides.length < 2", @ended="slides.length > 1 && next()", storageKey="video_carousel")

      //- (prev btn)
      .absolute.top-0.left-0.h-full.flex.items-center.justify-center.pointer-events-none(v-if="slides.length > 1", @click="prev", aria-label="Previous Video")
        button.p-20.md_p-40.pointer-events-auto
          svg-chevron-left.h-17.md_h-40.text-white
      //- (next btn)
      .absolute.top-0.right-0.h-full.flex.items-center.justify-center.pointer-events-none(v-if="slides.length > 1", @click="next", aria-label="Next Video")
        button.p-20.md_p-40.pointer-events-auto
          svg-chevron-right.h-17.md_h-40.text-white
</template>

<script>
export default {
  name: 'VideoCarousel',
  props: {
    slides: Array
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    next () {
      this.current = this.current + 1 === this.slides.length ? 0 : this.current + 1
    },
    prev () {
      this.current = this.current - 1 < 0 ? this.slides.length - 1 : this.current - 1
    }
  }
}
</script>

<style>
</style>
